<script lang="ts" setup>
const {
	topGamesArrayable,
	newGamesArrayable,
	lastGames,
	seoData,
	homePageData,
	jackpotGamesArrayable,
	banners,
	bannerEnabled,
	fishingGames,
	bonusBuyGames,
	holdNLinksGames,
	recommendGames,
	intoTheWildGames
} = useHomePage();
const isGuest = useIsGuest();

useHead({
	title: () => seoData.value?.seoTitle || "NoLimitCoins",
	meta: [
		{
			hid: "description",
			name: "description",
			content: () => seoData.value?.seoDescription
		},
		{
			name: "keywords",
			content: () => seoData.value?.seoKeywords || ""
		}
	]
});

const { isSweepStakes } = useSwitchMode();
const isCatalogGames = useState<boolean>("show-catalog-games", () => false);
const showBanners = computed(() => bannerEnabled.value && banners.value?.length && banners.value.length > 1);

onMounted(() => {
	isCatalogGames.value = false;
});
</script>

<template>
	<NuxtLayout>
		<LazyOHomeBanners v-if="showBanners" class="section-item" :banners="banners" />
		<div v-else class="section-item full-mob">
			<LazyOHomeQuickCash v-if="!isGuest" />
			<LazyOHomeSignUp v-if="isGuest" />
		</div>

		<LazyOGamesCatalog :show-catalog="isCatalogGames" />
		<template v-if="!isCatalogGames">
			<LazyOGameSlider
				v-if="!isGuest && lastGames?.length"
				class="section-item row-games"
				:games="lastGames"
				title="homePage.gamesCategory.recentlyPlayed"
				icon="24/recent"
			/>
			<LazyOGameSlider
				v-if="recommendGames?.length"
				class="section-item row-games"
				:games="recommendGames"
				title="homePage.gamesCategory.recommend"
				showAll
				icon="24/recommend"
				@view-all-action="navigateTo('/issues/recommend/')"
			/>
			<LazyOGameSlider
				class="section-item row-games"
				:games="topGamesArrayable"
				title="homePage.gamesCategory.topGames"
				showAll
				icon="24/games"
				@view-all-action="navigateTo('/issues/popular-games/')"
			/>
			<OLazyComponent id="home-promotions" class="section-item">
				<LazyOHomePromotions />
			</OLazyComponent>
			<OLazyComponent v-if="newGamesArrayable?.length" id="game-slider-hot" class="section-item row-games">
				<OGameSlider
					:games="newGamesArrayable"
					showAll
					title="homePage.gamesCategory.new"
					icon="24/new"
					@view-all-action="navigateTo('/issues/new-games/')"
				/>
			</OLazyComponent>
			<OLazyComponent v-if="bonusBuyGames?.length" id="game-slider-bonus-buy" class="section-item row-games">
				<OGameSlider
					:games="bonusBuyGames"
					showAll
					title="homePage.gamesCategory.bonusBuy"
					icon="24/bonus-buy"
					@view-all-action="navigateTo('/issues/bonus-buy/')"
				/>
			</OLazyComponent>
			<OLazyComponent id="latest-winners" class="section-item">
				<LazyOHomeLatestWinners />
			</OLazyComponent>
			<OLazyComponent id="home-jackpot" class="section-item">
				<OHomeJackpot :jackpot="homePageData?.jackpots" :jackpot-games="jackpotGamesArrayable" />
			</OLazyComponent>
			<OLazyComponent v-if="fishingGames?.length" id="game-slider-fishing" class="section-item row-games">
				<OGameSlider
					:games="fishingGames"
					showAll
					title="homePage.gamesCategory.fishing"
					icon="24/fish"
					@view-all-action="navigateTo('/issues/fishing/')"
				/>
			</OLazyComponent>

			<OLazyComponent v-if="holdNLinksGames?.length" id="home-hold-n-link" class="section-item row-games">
				<OGameSlider
					:games="holdNLinksGames"
					title="homePage.gamesCategory.holdNLink"
					icon="24/hold-n-link"
					showAll
					@view-all-action="navigateTo('/issues/hold-n-link/')"
				/>
			</OLazyComponent>
			<OLazyComponent v-if="intoTheWildGames?.length" id="home-wild" class="section-item row-games">
				<OGameSlider
					:games="intoTheWildGames"
					title="homePage.gamesCategory.intoTheWild"
					icon="24/wild"
					showAll
					@view-all-action="navigateTo('/issues/into-the-wild/')"
				/>
			</OLazyComponent>
			<OLazyComponent id="home-collections" class="section-item">
				<OHomeCollections />
			</OLazyComponent>
			<OLazyComponent v-if="isGuest" id="home-ratings" class="section-item">
				<OHomeRatings
					:jackpots="homePageData?.jackpots"
					:isSweepStakes="isSweepStakes"
					:last-big-win="homePageData?.infoPlate?.lastBigWinTournamentPoints"
				/>
			</OLazyComponent>

			<OLazyComponent v-if="showBanners" class="section-item full-mob">
				<OHomeSignUp v-if="isGuest" />
				<OHomeQuickCash v-else />
			</OLazyComponent>
		</template>
	</NuxtLayout>
</template>

<style lang="scss" scoped>
.section-item {
	max-width: 1428px;
	margin: 0 auto;
	padding: 0 27px;

	@media (min-width: 1450px) {
		&.row-games {
			padding: 0 18px;

			&:deep(.header-slider) {
				padding: 0px 8px;
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 0 16px;

		&.full-mob {
			padding: 0;

			&:first-child {
				margin-bottom: 12px;
			}
		}
	}

	& + .section-item {
		padding-top: 42px;

		@include media-breakpoint-down(md) {
			padding-top: 34px;
		}
	}

	& + .section-item {
		padding-top: 42px;

		@include media-breakpoint-down(md) {
			padding-top: 34px;
		}
	}
}

.fixed + .section-item {
	padding-top: 20px;
}
</style>
